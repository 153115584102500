import { BrowserRouter, Route, Routes } from "react-router-dom";
import Create from "./components/Create";
import Read from "./components/Read";
import Edit from "./components/Edit";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Read />} />
        <Route exact path="/create" element={<Create />} />
        <Route exact path="/Edit" element={<Edit></Edit>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
